import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { routePaths } from 'routes/Routes';

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const language = searchParams.get('language') || 'en';

  return (
    <Result
      status='404'
      title='404'
      subTitle={'Sorry, the page you visited does not exist.'}
      extra={
        <Button
          type='primary'
          onClick={() => navigate(`${routePaths.VALVES}?language=${language}`)}
        >
          {'Back to Home'}
        </Button>
      }
    />
  );
};

export default NotFound;
