import { Divider, Layout } from 'antd';
import { ConfigProvider } from 'antd';
import roRO from 'antd/locale/ro_RO';
import huHU from 'antd/locale/hu_HU';
import enUS from 'antd/locale/en_US';
import Sidebar from 'components/common/Sidebar/Sidebar';
import Header from 'components/specific/Header/Header';
import { ReactComponent as TimelineIcon } from 'assets/icons/list-timeline2.svg';
import { ReactComponent as ValvesIcon } from 'assets/icons/pipe2.svg';
import { useEffect, useMemo, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Devices from 'views/Devices/Devices';
import ProfilePage from 'views/ProfilePage/ProfilePage';
import styles from './BaseLayout.module.scss';
import DeviceEvents from 'views/DeviceEvents/DeviceEvents';
import { useDispatch, useSelector } from 'react-redux';
import { Device } from 'interfaces/devices/Devices.interface';
import { setSelectedDevice } from 'store/slices/devices.slice';
import { styled } from 'styled-components';
import { ReactComponent as Sensor } from 'assets/icons/sensor.svg';
import { routePaths } from 'routes/Routes';
import { createDeviceUtils } from 'utils/device.utils';
import variables from '../styles/variables.module.scss';
import Valves from 'views/Actuators/Actuators';
import Timeline from 'views/Timeline/Timeline';
import useTranslations from 'hooks/useTranslations';
import { languageSelector } from 'store/slices/language.slice';
import { Locale } from 'antd/es/locale';
import useIsMobile from 'hooks/useIsMobile';
import NotFound from 'views/NotFound/NotFound';

const { Content } = Layout;

const StyledDivider = styled(Divider)`
  && {
    border-color: #ddd;
  }
`;
interface BaseLayoutProps {
  user: any;
  selectedRoute: string;
  children?: React.ReactNode;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({ user }) => {
  const isMobile = useIsMobile();
  const [collapsed, setCollapsed] = useState(isMobile);
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const navigate = useNavigate();
  const { devicesList } = useSelector((state: any) => state.devices);
  const [searchParams] = useSearchParams();
  const { translate } = useTranslations();
  const deviceUtils = createDeviceUtils(translate);
  const language = useSelector(languageSelector).language;
  const browserLanguage = navigator.language.split('-')[0];

  const currentLanguage = searchParams.get('language') || language || browserLanguage || 'en';

  const getAntLanguage = (lang: string): Locale => {
    switch (lang) {
      case 'ro':
        return roRO;
      case 'hu':
        return huHU;
      default:
        return enUS;
    }
  };

  const menuItems = useMemo(
    () => [
      {
        key: '1',
        label: translate('tr_valves'),
        path: routePaths.VALVES,
        icon: <ValvesIcon className={`${styles.sensorIcon} ${styles.valvesIcon}`} />,
        title: translate('tr_valves'),
        content: <Valves />,
      },
      {
        key: '2',
        label: translate('tr_devices'),
        path: routePaths.SENSORS,
        icon: <Sensor className={`${styles.sensorIcon} ${styles.sensorListIcon}`} />,
        title: translate('tr_devices'),
        content: <Devices />,
      },
      {
        key: '3',
        label: translate('tr_timeline'),
        path: routePaths.TIMELINE,
        icon: <TimelineIcon className={`${styles.sensorIcon} ${styles.timelineListIcon}`} />,
        title: translate('tr_timeline'),
        content: <Timeline />,
      },
    ],
    [translate]
  );
  const userItem = {
    key: '4',
    label: translate('tr_user'),
    path: routePaths.PROFILE,
    icon: <UserOutlined className={`${styles.userIcon}`} />,
    title: translate('tr_user'),
    content: <ProfilePage />,
  };

  const notFound = {
    key: '4',
    label: translate('tr_user'),
    path: routePaths.NOTFOUND,
    icon: <UserOutlined className={`${styles.userIcon}`} />,
    title: translate('tr_user'),
    content: <NotFound />,
  };

  let mobileItems = menuItems.concat(userItem);

  const location = useLocation();
  const dispatch = useDispatch();
  let selectedDevice = useSelector((state: any) => state.devices.selectedDevice);
  const [selectedDeviceOffline, setSelectedDeviceOffline] = useState(false);

  useEffect(() => {
    const selectedItem = mobileItems.find((item) => item.path === location.pathname);
    let key = selectedItem?.key || null;
    if (location.pathname === '/') {
      key = '1';
    }

    setSelectedKey(key);
  }, [location.pathname, menuItems, mobileItems]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const hasOfflineDevices = devicesList.some((device: Device) =>
    deviceUtils.isDeviceOffline(device)
  );

  useEffect(() => {
    const deviceId = searchParams.get('id');
    const device = devicesList.find((device: Device) => device.devEui === deviceId);
    if (device) {
      setSelectedDeviceOffline(deviceUtils.isDeviceOffline(device));
      dispatch(setSelectedDevice(device));
    }
  }, [location.search, devicesList, dispatch, searchParams, deviceUtils]);

  let content, title;
  switch (location.pathname) {
    case routePaths.VALVES:
      content = menuItems[0].content;
      title = menuItems[0].title;
      break;
    case routePaths.SENSORS:
      content = menuItems[1].content;
      title = menuItems[1].title;
      break;
    case routePaths.TIMELINE:
      content = menuItems[2].content;
      title = menuItems[2].title;
      break;
    case routePaths.DEVICE:
      content = (
        <DeviceEvents
          deviceId={selectedDevice?.devEui}
          key={selectedDevice?.devEui}
          isOffline={selectedDeviceOffline}
        />
      );
      title = (
        <div>
          <span>{translate('tr_devices')}</span> <StyledDivider type='vertical' />{' '}
          <span>{selectedDevice?.name}</span>
        </div>
      );
      break;
    case routePaths.PROFILE:
      content = <ProfilePage />;
      title = translate('tr_reset_password');
      break;
    case '/':
      content = menuItems[0].content;
      title = menuItems[0].title;
      break;
    default:
      content = notFound.content;
      title = notFound.title;
      break;
  }

  const handleMenuItemSelect = (item: any) => {
    navigate(item.path);
  };

  const renderMobileMenu = () => {
    return (
      <div className={styles.mobileMenu}>
        {mobileItems.map((item) => (
          <div
            key={item.key}
            className={`${styles.mobileMenuItem} ${
              selectedKey === item.key ? styles.mobileMenuItemSelected : ''
            }`}
            onClick={() => handleMenuItemSelect(item)}
          >
            {item.icon}
            <span className={styles['mobileItem' + item.key]}>{item.label}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Layout className={styles.containerLayout}>
      <ConfigProvider
        locale={getAntLanguage(currentLanguage || 'en')}
        wave={{ disabled: true }}
        theme={{
          token: {
            colorPrimary: `${variables.primaryColor}`,
          },
        }}
      >
        {!isMobile && (
          <Sidebar
            collapsed={collapsed}
            toggleCollapsed={toggleCollapsed}
            menuItems={menuItems}
            onItemSelect={handleMenuItemSelect}
            selectedKey={selectedKey}
          />
        )}
        <Layout className={styles.contentLayout}>
          <Header hasUser={user ? true : false} text={title} hasWarnings={hasOfflineDevices} />
          <Content
            id='scrollableContent'
            style={{
              height: 'calc(100vh - 64px - 54px))',
              overflow: 'auto',
            }}
            className={styles.siteLayoutBackground}
          >
            {content}
          </Content>
          {/* {process.env.REACT_APP_VERSION && ( */}
          {!isMobile && (
            <div className={styles.footer}>Version: {process.env.REACT_APP_VERSION}</div>
          )}
          {/* )} */}
          {isMobile && renderMobileMenu()}
        </Layout>
      </ConfigProvider>
    </Layout>
  );
};

export default BaseLayout;
