import useTranslation from 'hooks/useTranslations';
import styles from './ProfilePage.module.scss';
import ResetPassword from 'components/specific/ResetPassword/ResetPasword';
import useIsMobile from 'hooks/useIsMobile';
import { useDispatch, useSelector } from 'react-redux';
import { useLogoutMutation } from 'services/auth.service';
import { useNavigate } from 'react-router-dom';
import { setUser } from 'store/slices/auth.slice';
import { setSelectedTenant, setTenants } from 'store/slices/tenants.slice';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useState } from 'react';

const ProfilePage = () => {
  const { translate } = useTranslation();
  const isMobile = useIsMobile();

  const { user } = useSelector((state: any) => state.auth);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [logout] = useLogoutMutation();

  const [showResetPassword, setShowResetPassword] = useState(false);

  const onLogout = () => {
    try {
      logout({});
      dispatch(setUser(null));
      dispatch(setTenants([]));
      dispatch(setSelectedTenant(null));
      navigate('/login');
    } catch (error: any) {
      console.log('Error logging out', error);
    }
  };

  const onReset = () => {
    setShowResetPassword(!showResetPassword);
  };

  const renderMobileUser = () => {
    return (
      <div className={styles.mobileUser}>
        <div className={styles.user}>
          <div className={styles.userBubble}>
            {user?.firstName[0].toUpperCase()}
            {user?.lastName[0].toUpperCase()}
          </div>
          <div className={styles.userInfo}>
            <div className={styles.userRow}>
              <span className={styles.userLabel}>Name:</span>
              <span className={styles.userValue}>
                {user?.firstName} {user?.lastName}
              </span>
            </div>
            <div className={styles.userRow}>
              <span className={styles.userLabel}>Email:</span>
              <span className={styles.userValue}>{user?.email}</span>
            </div>
            <div className={styles.userRow}>
              <span className={styles.userLabel}>Password:</span>
              <span className={styles.resetPassword} onClick={onReset}>
                {translate('tr_reset_password')}
              </span>
            </div>
          </div>
          <div className={styles.userActions}>
            <Button className={styles.logout} onClick={onLogout}>
              {translate('tr_logout')}
            </Button>
          </div>
        </div>

        <div
          className={`${styles.resetFormMobileContainer} ${showResetPassword ? styles.visible : ''}`}
        >
          <div className={styles.headerBack}>
            <div>{translate('tr_reset_password')}</div>
            <div onClick={onReset} className={styles.backLink}>
              <LeftOutlined /> {translate('tr_back')}
            </div>
          </div>
          <div className={styles.resetFormMobile}>
            <ResetPassword />
          </div>
        </div>
      </div>
    );
  };

  const renderDesktopProfile = () => {
    return (
      <>
        <h1 className={styles.resetTitle}>{translate('tr_reset_password')}</h1>
        <div className={styles.resetContainer}>
          <div className={styles.resetForm}>
            <ResetPassword />
          </div>
        </div>
      </>
    );
  };
  return <>{isMobile ? renderMobileUser() : renderDesktopProfile()}</>;
};
export default ProfilePage;
