import { Device } from 'interfaces/devices/Devices.interface';
import styles from './PowerStatus.module.scss';

import { powerStatus } from 'config/constant';
import { ReactComponent as BatteryLow } from 'assets/icons/battery-empty.svg';
import { ReactComponent as PowerCable } from 'assets/icons/plug.svg';
import variables from 'styles/variables.module.scss';
import useTranslations from 'hooks/useTranslations';
import { createDeviceUtils } from 'utils/device.utils';

interface PowerStatusProps {
  device: Device | null;
}

const getPowerColor = (devicePowerStatus: string) => {
  if (devicePowerStatus === powerStatus.BATTERY_HIGH) {
    return 'green';
  } else if (devicePowerStatus === powerStatus.BATTERY_MEDIUM) {
    return 'orange';
  } else if (devicePowerStatus === powerStatus.BATTERY_LOW) {
    return 'red';
  }
};
export const PowerStatus: React.FC<PowerStatusProps> = ({ device }) => {
  const { translate } = useTranslations();
  const deviceUtils = createDeviceUtils(translate);

  const devicePowerStatus = deviceUtils.getPowerStatus(device);
  const batteryClass = 'battery_' + getPowerColor(devicePowerStatus);
  const getBatteryIcon = () => {
    if (devicePowerStatus === powerStatus.POWER_CABLE) {
      return <PowerCable className={`${styles.batteryIcon} ${styles.batteryPlugged}`} />;
    } else if (devicePowerStatus === powerStatus.POWER_NO_INFO) {
      return (
        <div className={styles.batteryNotSet}>
          <BatteryLow className={`${styles.batteryIcon} ${styles.batteryNone}`} /> ...
        </div>
      );
    } else {
      return <BatteryLow className={`${styles.batteryIcon} ${styles[batteryClass]}`} />;
    }
  };

  const showBatteryPercent = (devicePowerStatus: string) => {
    return (
      devicePowerStatus === powerStatus.BATTERY_HIGH ||
      devicePowerStatus === powerStatus.BATTERY_MEDIUM ||
      devicePowerStatus === powerStatus.BATTERY_LOW ||
      devicePowerStatus === powerStatus.BATTERY_EMPTY
    );
  };

  const getBatteryText = (device: Device | null) => {
    if (devicePowerStatus === powerStatus.POWER_NO_INFO) {
      return '';
    } else {
      let batteryLevel = device?.latestEvent?.parameters?.battery || null;
      if (!batteryLevel) {
        return '0%';
      } else if (batteryLevel > 100) {
        //power cable
        return '-';
      } else {
        return batteryLevel + '%';
      }
    }
  };

  const getBatteryChangeWidth = (device: any) => {
    const batteryPercentage = getBatteryText(device);
    const batteryWidth = Number(variables.batteryWidth);
    const widthInPixels = (parseFloat(batteryPercentage) / 100) * batteryWidth;
    return Math.ceil(widthInPixels) || 0;
  };

  return (
    <div className={styles.batteryStatus}>
      <div className={styles.batteryIcon}>
        <span
          className={styles.batteryCharge}
          style={{
            width: getBatteryChangeWidth(device),
            backgroundColor: getPowerColor(devicePowerStatus),
            display: showBatteryPercent(devicePowerStatus) ? 'block' : 'none',
          }}
        ></span>
        {getBatteryIcon()}
      </div>
      <span className={styles.batteryText}>{getBatteryText(device)}</span>
    </div>
  );
};
