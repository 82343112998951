import React from 'react';
import styles from './Header.module.scss';
import Profile from '../Profile/Profile';

import { Layout, Tooltip } from 'antd';
import { styled } from 'styled-components';
import variables from '../../../styles/variables.module.scss';
import TenantSelector from '../TenantSelector/TenantSelector';
import { useAppSelector } from 'store/hooks';
import { tenantSelector } from 'store/slices/tenants.slice';

import LanguageSelector from '../LanguageSelector/LanguageSelector';
import useTranslation from 'hooks/useTranslations';
import useIsMobile from 'hooks/useIsMobile';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Header: AntHeader } = Layout;

interface HeaderProps {
  text: any;
  hasUser: boolean;
  hasWarnings?: boolean;
  children?: React.ReactNode;
}

export const StyledHeader = styled(AntHeader)`
  background: #fff;
  border-bottom: 1px solid #d3e6ed;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: ${variables.headerZIndex};
`;

const Header: React.FC<HeaderProps> = ({ hasUser, text, hasWarnings }) => {
  const { tenantsList } = useAppSelector(tenantSelector);
  const { translate } = useTranslation();

  const isMobile = useIsMobile();
  const messageWarning = (
    <div className={styles.tooltip}>
      <p>{translate('tr_offline_device_warning1')} </p>
      <p>{translate('tr_offline_device_warning2')} </p>
    </div>
  );

  return (
    <StyledHeader>
      {hasUser ? (
        <div className={styles.headerContainer}>
          <div className={styles.headerTitle}>{text}</div>
          {tenantsList.length > 1 && <TenantSelector />}
          <div className={styles.headerOptions}>
            {hasWarnings && (
              <Tooltip placement='bottom' title={messageWarning} arrow={false} color='white'>
                <div className={styles.headerWarningIcon}>
                  <ExclamationCircleOutlined />
                </div>
              </Tooltip>
            )}
            <div className={styles.headerLanguage}>
              <LanguageSelector />
            </div>
            {!isMobile && <Profile />}
          </div>
        </div>
      ) : null}
    </StyledHeader>
  );
};

export default Header;
