import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import BaseLayout from 'layout/BaseLayout';
import Login from 'views/Login/Login';
import Valves from 'views/Actuators/Actuators';
import Devices from 'views/Devices/Devices';
import { useAuth } from 'hooks/useAuth';
import ProfilePage from 'views/ProfilePage/ProfilePage';
import FirstLoginResetPassword from 'views/FirstLoginResetPassword/FirstLoginResetPassword';
import { routePaths } from './Routes';
import Timeline from 'views/Timeline/Timeline';
import TokenService from 'services/token.service';
import { useLogoutMutation } from 'services/auth.service';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/slices/auth.slice';
import Mixpanel from 'utils/mixpanel.utils';
import { useLocation } from 'react-router-dom';

const AppRouter: React.FC = () => {
  const { user } = useAuth();
  const [logout] = useLogoutMutation();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      if (!TokenService.isLoggedIn()) {
        TokenService.logoutUser();
        dispatch(setUser(null));
      }
    };
    checkAuth();
  }, [dispatch, logout]);

  useEffect(() => {
    Mixpanel.track(`Page View ${location.pathname}`, { page: location.pathname, user: user });
  }, [location.pathname, user]);

  return (
    <Routes>
      {/* Always allow access to the login page but redirect if already logged in */}
      <Route
        path={routePaths.LOGIN}
        element={
          !user ? (
            <Login />
          ) : user.FirstLogin ? (
            <Navigate to={routePaths.RESET_PASSWORD} replace />
          ) : (
            <Navigate to={routePaths.VALVES} replace />
          )
        }
      />
      <Route path={routePaths.RESET_PASSWORD} element={<FirstLoginResetPassword />} />
      {/* Handling routes when a user is logged in */}
      {user ? (
        user.firstLogin ? (
          // Redirect all routes to reset password if it's the user's first login
          <Route path='*' element={<Navigate to={routePaths.RESET_PASSWORD} replace />} />
        ) : (
          // Normal routes for users who are not on their first login
          <Route
            path='/'
            element={<BaseLayout user={user} selectedRoute={routePaths.VALVES}></BaseLayout>}
          >
            <Route index element={<Navigate to={routePaths.VALVES} replace />} />
            <Route path={routePaths.VALVES} element={<Valves />} />
            <Route path={routePaths.TIMELINE} element={<Timeline />} />
            <Route path={routePaths.SENSORS} element={<Devices />} />
            <Route path={routePaths.PROFILE} element={<ProfilePage />} />
            <Route path='*' element={<Navigate to={routePaths.VALVES} replace />} />
          </Route>
        )
      ) : (
        // If no user is logged in, redirect all routes to login
        <Route path='*' element={<Navigate to={routePaths.LOGIN} replace />} />
      )}
    </Routes>
  );
};

export default AppRouter;
