import styled from 'styled-components';
import { Table } from 'antd';

const StyledTable = styled(Table)`
  &&& .ant-table {
     color: darkslategrey;
     font-weight: 300;
    box-shadow:
      0 0px 0px rgba(0, 0, 0, 0.05),
      0 3px 5px rgba(0, 0, 0, 0.05);
    border-radius: 0;
    border: 1px solid rgba(211, 230, 237, 1);
    .ant-table-title {
      background-color: #f0f0f0;
      border-bottom: 1px solid rgba(211, 230, 237, 1);
      border-radius: 0;
   
    }
    .ant-table-cell {
       color: darkslategrey;
      b {
        font-weight: 600;
        }
    }  
    tr {
      cursor: pointer;
    }
    .row-green {
      width: 5px;
      background-color: green;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .row-red {
      width: 5px;
      background-color: red;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      }
    }
  }
`;

export default StyledTable;
