import mixpanel, { Dict } from 'mixpanel-browser';

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || '';
mixpanel.init(MIXPANEL_TOKEN, {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});

const Mixpanel = {
  track: (event: string, properties: Dict | undefined) => {
    mixpanel.track(event, properties);
  },
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  people: {
    set: (properties: Dict) => {
      mixpanel.people.set(properties);
    },
  },
};

export default Mixpanel;
