import { useState, useMemo, memo, useCallback, useEffect } from 'react';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEditDeviceMutation } from 'services/devices.service';
import { CustomStyledInput } from 'components/common/Input/Input.styles';
import { LoadingOutlined } from '@ant-design/icons';
import { random } from 'lodash';

import { Button, Col, Empty, message, Row, Space, Spin, Tooltip } from 'antd';
import {
  QuestionCircleOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { RangePickerProps } from 'antd/es/date-picker';

import useUrlParams from 'hooks/useUrlParams';
import useFetchData from './hooks/useFetchData';
import { useDateRange } from './hooks/useDateRange';

import CustomTabs from 'components/common/Tabs/CustomTabs';
import Graph from 'components/specific/Graph/Graph';
import Dropdown from 'components/common/Dropdown/Dropdown';
import DateRangePicker from 'components/common/DatePicker/DateRangePicker';

import DeviceOverview from './DeviceOverview';
import { getGraphTab } from './GraphTabs';

import { useAppSelector } from 'store/hooks';
import { deviceSelector, setSelectedDevice } from 'store/slices/devices.slice';
import { useGetLastOfflineTimestampQuery } from 'services/deviceEvents.service';

import { generateMockData } from 'utils/mockGraphsData';
import {
  formatTick,
  getBrushClass,
  getGraphLeftMargin,
  getMinResolutionLimitation,
  getNextFinerResolution,
  getResolution,
  getResolutionBasedOnInterval,
} from 'utils/graph.utils';

import './DeviceEvents.scss';
import styles from './DeviceEvents.module.scss';
import { dateRangeOptions, RESOLUTIONS } from 'config/constant';
import { DeviceStatus } from 'components/specific/DeviceInfo/DeviceStatus/DeviceStatus';
import { PowerStatus } from 'components/specific/DeviceInfo/PowerStatus/PowerStatus';
import { createDeviceUtils } from 'utils/device.utils';
import { Device } from 'interfaces/devices/Devices.interface';
import { useDispatch } from 'react-redux';
import { DeviceEvent } from 'interfaces/devices/DeviceEvents.interface';
import { ReactComponent as ActuatorIllustration } from 'assets/icons/actuators_illustration2.svg';
import { Link, useNavigate } from 'react-router-dom';
import useTranslations from 'hooks/useTranslations';
import useIsMobile from 'hooks/useIsMobile';
import Mixpanel from 'utils/mixpanel.utils';

dayjs.extend(duration);
dayjs.extend(relativeTime);
type DeviceDetailsViewProps = {
  deviceId: string;
  isOffline: boolean;
};

const DeviceEventsView: React.FC<DeviceDetailsViewProps> = memo(({ deviceId, isOffline }) => {
  const [urlParams, updateUrlParams] = useUrlParams();

  const { selectedDevice } = useAppSelector(deviceSelector);
  const [isDot, setIsDot] = useState(true);
  const [editNameMode, setEditNameMode] = useState(false);
  const [editValue, setEditValue] = useState('');
  const [editDevice] = useEditDeviceMutation();
  const { translate } = useTranslations();
  const deviceUtils = createDeviceUtils(translate);
  const isMobile = useIsMobile();

  const dispatch = useDispatch();

  const [zoomDomain, setZoomDomain] = useState<[number, number] | null>(null);

  const [, loading, fetchData] = useFetchData(deviceId);
  const navigate = useNavigate();

  const selectedTimeImterval = urlParams.timeOption || dateRangeOptions.lastDayOptionId;
  const [viewEventsList, setViewEventsList] = useState<DeviceEvent[]>([]);

  const [cachedEvents, setCachedEvents] = useState<{ [key: string]: DeviceEvent[] }>({});
  const [resolution, setResolution] = useState(RESOLUTIONS.HOURLY);

  const lastEvent = viewEventsList[viewEventsList.length - 1];

  const deviceVersion = deviceUtils.getSensorVersion(lastEvent);

  const deviceParameters = deviceUtils.getDeviceSensors(viewEventsList);

  const { tooltipDateRange, ticks, handleDateRangeChange, handleSelectChange, getNoDataMessage } =
    useDateRange({
      selectedOption: urlParams.timeOption,
      urlParams,
      updateUrlParams,
      eventsList: viewEventsList,
    });

  const MIN_RESOLUTION_LIMIT = getMinResolutionLimitation(
    tooltipDateRange.startDate,
    tooltipDateRange.endDate
  );

  const fetchAndCacheData = useCallback(
    async (startDate: number, endDate: number, resolution: string) => {
      const data = await fetchData(startDate, endDate, resolution);
      setCachedEvents((prevCache) => ({ ...prevCache, [resolution]: data }));
      return data;
    },
    [fetchData]
  );

  const handleZoomGraph = async (newZoomDomain: [number, number] | null) => {
    if (newZoomDomain) {
      setZoomDomain(newZoomDomain);

      let newResolution = getResolutionBasedOnInterval(newZoomDomain, MIN_RESOLUTION_LIMIT);
      //TODO  - find a better solution for when there is not enought data on a device
      //and on zoom out if gies to a higher resolution even if it should not becaouse there is not enought data
      // with this solution there is a glitch when there is enought data and it goes to a higher resolution
      // if (isResolutiuonBigger(newResolution, resolution)) {
      //   newResolution = resolutionLoad;
      // }

      if (newResolution && newResolution !== resolution) {
        if (cachedEvents[newResolution]) {
          // Use cached data if available
          setViewEventsList(cachedEvents[newResolution]);
        } else {
          // Fetch and cache data if not already cached
          fetchAndCacheData(
            tooltipDateRange.startDate,
            tooltipDateRange.endDate,
            newResolution
          ).then((data) => {
            data && setViewEventsList(data);
          });
        }
        setResolution(newResolution);

        // Pre-fetch next finer resolution for future zooms
        const nextResolution = getNextFinerResolution(newResolution);

        if (nextResolution && !cachedEvents[nextResolution]) {
          fetchAndCacheData(tooltipDateRange.startDate, tooltipDateRange.endDate, nextResolution);
        }
      }
    }
  };

  const loadData = async () => {
    if (tooltipDateRange.startDate && tooltipDateRange.endDate) {
      const newResolution = await getResolution(
        [tooltipDateRange.startDate, tooltipDateRange.endDate],
        fetchData
      );
      if (newResolution) {
        // setResolutionLoad(newResolution);
        const data = await fetchAndCacheData(
          tooltipDateRange.startDate,
          tooltipDateRange.endDate,
          newResolution
        );
        data && setViewEventsList(data);
      }
      const nextResolution = getNextFinerResolution(newResolution);
      if (nextResolution) {
        fetchAndCacheData(tooltipDateRange.startDate, tooltipDateRange.endDate, nextResolution);
      }
    }
  };

  useEffect(() => {
    setZoomDomain([tooltipDateRange.startDate, tooltipDateRange.endDate]);
    setCachedEvents({});
    loadData();
    // eslint-disable-next-line
  }, [tooltipDateRange.startDate, tooltipDateRange.endDate]);

  const deviceProfileId = selectedDevice?.deviceProfileId || null;
  const lastOfflineTimestamp =
    useGetLastOfflineTimestampQuery(
      { deviceId, deviceProfileId },
      { skip: !deviceProfileId || !deviceId }
    ).data || 0;

  const handleEditDeviceName = (device: Device | null) => {
    if (device) {
      setEditNameMode(true);
      setEditValue(device.name);
    }
  };

  const handleCancel = () => {
    setEditNameMode(false);
  };

  const handleSave = async ({ device }: { device: Device | null }) => {
    if (!editValue || !device) {
      message.error('Device name cannot be empty');
      return;
    }
    await editDevice({ deviceId: device.devEui, newName: editValue });
    Mixpanel.track('Device Name Changed', {
      deviceId: device.devEui,
      user: localStorage.getItem('user'),
      newName: editValue,
    });
    dispatch(setSelectedDevice({ ...device, name: editValue }));
    setEditNameMode(false);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSave({ device: selectedDevice });
    }
  };

  const renderEditDeviceName = () => {
    return (
      <div className={styles.editContainer}>
        <CustomStyledInput
          value={editValue}
          onChange={(e: any) => setEditValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Space className={styles.actionIcons}>
          <CheckOutlined
            className={styles.checkIcon}
            onClick={() => handleSave({ device: selectedDevice })}
          />
          <CloseOutlined className={styles.closeIcon} onClick={handleCancel} />
        </Space>
      </div>
    );
  };

  let deviceData = [
    {
      id: 'deviceName',
      label: translate('tr_name'),
      value: selectedDevice?.name,
      tooltip: null,
      action: () => handleEditDeviceName(selectedDevice),
      hasAction: true,
    },
    {
      id: 'deviceStatus',
      label: translate('tr_status'),
      value: <DeviceStatus device={selectedDevice} hasMobileText={true} />,
      tooltip: deviceUtils.getDeviceStatusTooltipMessage(isOffline),
      tooltipClassName: styles.deviceStatusTooltip,
    },
    {
      id: 'deviceLatSeen',
      label: translate('tr_last_seen'),
      value: deviceUtils.getDeviceLastSeen(selectedDevice, true),
      tooltip: translate('tr_last_seen_info'),
    },
    {
      id: 'deviceUptime',
      label: translate('tr_uptime'),
      value: deviceUtils.getUptime(selectedDevice, lastOfflineTimestamp),
      tooltip: deviceUtils.getDeviceUptimeTooltipMessage(!isOffline),
    },
    {
      id: 'devicePower',
      label: translate('tr_power'),
      value: <PowerStatus device={selectedDevice} />,
      tooltip: deviceUtils.getDevicePowerTooltipMessage(deviceUtils.getPowerStatus(selectedDevice)),
    },
  ];

  const defaultStartDate = useMemo(
    () => (urlParams.startDate ? dayjs.unix(Number(urlParams.startDate)) : null),
    [urlParams.startDate]
  );
  const defaultEndDate = useMemo(
    () => (urlParams.endDate ? dayjs.unix(Number(urlParams.endDate)) : null),
    [urlParams.endDate]
  );

  const onChangeTab = useCallback(
    async (key: string) => {
      updateUrlParams({
        ...urlParams,
        tab: key,
      });

      let newResolution = await getResolution(
        [tooltipDateRange.startDate, tooltipDateRange.endDate],
        fetchData
      );
      const tabParameterView = getGraphTab(deviceVersion, deviceParameters, translate).find(
        (tab) => tab.key === key
      );
      Mixpanel.track('Device Parameter View', {
        tab: tabParameterView?.graphLabel,
        deviceId: selectedDevice?.devEui,
        user: localStorage.getItem('user'),
        tiemeframeLabel: selectedTimeImterval,
        parameter: tabParameterView?.graphParameter,
        timeframe: [
          dayjs.unix(tooltipDateRange.startDate).format('YYYY-MM-DD'),
          dayjs.unix(tooltipDateRange.endDate).format('YYYY-MM-DD'),
        ],
      });
      setCachedEvents({});
      newResolution && setResolution(newResolution);
      setZoomDomain([tooltipDateRange.startDate, tooltipDateRange.endDate]);
      loadData();
    },

    // eslint-disable-next-line
    [updateUrlParams, urlParams, tooltipDateRange.startDate, tooltipDateRange.endDate]
  );

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Disable days after today
    return current && current > dayjs().endOf('day');
  };

  const renderDeviceData = () => {
    return (
      selectedDevice?.name && (
        <div className={styles.deviceDataContainer}>
          <div className={styles.deviceDataHeader}>
            <div> {translate('tr_device_details')}</div>
            <div
              className={styles.backButton}
              onClick={() => {
                navigate('/devices');
              }}
            >
              <LeftOutlined />
              {translate('tr_back')}
            </div>
          </div>
          <div className={styles.deviceDataContent}>
            {deviceData.map((row) => (
              <div className={styles.deviceDataRow}>
                <div className={styles.deviceDataLabel}>{row.label}</div>
                <div className={styles.deviceDataValue}>
                  {editNameMode && row.id === 'deviceName' ? (
                    renderEditDeviceName()
                  ) : (
                    <>
                      <span>{row.value}</span>
                      {row.hasAction && (
                        <EditOutlined className={styles.editDevice} onClick={row.action} />
                      )}
                    </>
                  )}
                  {row.tooltip && (
                    <Tooltip
                      placement='bottom'
                      title={<span className={styles.deviceDataTootlipMessage}>{row.tooltip}</span>}
                      arrow={false}
                      color='white'
                      className={row.tooltipClassName ? row.tooltipClassName : ''}
                    >
                      <QuestionCircleOutlined className={styles.deviceInfoExtra} />
                    </Tooltip>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    );
  };

  const renderTabContent = (currentTab: {
    key: string;
    graphParameter: string;
    graphLabel: string;
  }) => {
    //verify if there is data for current interval
    const tabData = viewEventsList.reduce(
      (acc, event) => {
        const value = event.parameters[currentTab.graphParameter.replace('parameters.', '')];
        if (value !== undefined) {
          acc.push(value);
        }
        return acc;
      },
      [] as Array<number | null>
    );

    const shouldShowMockData = viewEventsList.length === 0 || tabData.length === 0;
    const dataToShow = shouldShowMockData ? generateMockData() : viewEventsList;

    const blurClass = shouldShowMockData ? styles.blurContent : '';

    // If the tab is the overview tab, render the overview component
    if (currentTab.key === '1') {
      return (
        <div className={styles.overviewContent}>
          <DeviceOverview
            deviceId={deviceId}
            graphTabs={getGraphTab(deviceVersion, deviceParameters, translate)}
          />
        </div>
      );
    } else {
      return (
        <>
          <div className={styles.deviceEventFilterContainer} key={currentTab.key + random}>
            <Row gutter={16} align='middle'>
              <Col>
                <Dropdown
                  id='dropdown'
                  options={[
                    { value: dateRangeOptions.lastDayOptionId, label: translate('tr_last_day') },
                    { value: dateRangeOptions.lastWeekOptionId, label: translate('tr_last_week') },
                    {
                      value: dateRangeOptions.lastMonthOptionId,
                      label: translate('tr_last_month'),
                    },
                    {
                      value: dateRangeOptions.dateRangeOptionId,
                      label: translate('tr_date_range'),
                    },
                  ]}
                  defaultValue={selectedTimeImterval}
                  value={selectedTimeImterval}
                  onChange={handleSelectChange}
                />
              </Col>
              {selectedTimeImterval === dateRangeOptions.dateRangeOptionId && (
                <Col>
                  <Space direction='vertical' size={12}>
                    <DateRangePicker
                      onChange={handleDateRangeChange}
                      defaultValue={[defaultStartDate, defaultEndDate]}
                      disabledDate={disabledDate}
                    />
                  </Space>
                </Col>
              )}
            </Row>
          </div>

          <div className={blurClass}>
            <div className={styles.deviceEventContainer}>
              <div className={styles.scrollMessage}>{translate('tr_scroll_message')}</div>
              <Graph
                eventsList={dataToShow}
                parameter={currentTab.graphParameter}
                parameterLabel={currentTab.graphLabel}
                zoomDomain={zoomDomain}
                setZoomDomain={handleZoomGraph}
                urlParams={urlParams}
                ticks={ticks}
                isDot={isDot}
                setIsDot={setIsDot}
                graphMargin={{
                  top: 50,
                  right: 20,
                  left: getGraphLeftMargin(currentTab.graphParameter),
                  bottom: 40,
                }}
                getTickFormatter={(timestamp) => formatTick(timestamp, tooltipDateRange)}
                getBrushClass={() => getBrushClass(tooltipDateRange)}
              />
            </div>
          </div>
          {shouldShowMockData && (
            <div className={styles.noDataOverlay}>
              <div className={styles.noDataMessage}>{getNoDataMessage()}</div>
            </div>
          )}
        </>
      );
    }
  };

  const renderDeviceEvents = () => {
    //if no sensors are available, check battery or actuators
    if (deviceUtils.getDeviceSensors(viewEventsList).length === 0) {
      if (deviceUtils.hasActuators(viewEventsList)) {
        //if actuators are available, show actuators - do not show battery because actuators devices are with power cable
        return (
          <div className='emptyContainer'>
            <ActuatorIllustration className='actuatorIllustration' />
            <div className='emptyContent'>
              <p className='emptyTitle'>{translate('tr_actuators_message_title')}</p>
              <p className='emptyDescription'>{translate('tr_actuators_message_description')}</p>
              <Link to='/valves'>
                <Button className={styles.actuatorsSelector}>
                  {translate('tr_actuators_message_button')}
                </Button>
              </Link>
            </div>
          </div>
        );
      } else if (!loading) {
        return (
          <div className='emptyContainer'>
            <Empty className='emptyIllustration' description='' />
            <div className='emptyContent'>
              <p className='emptyTitle'>{translate('tr_no_data')}</p>
              <p className='emptyDescription'>{translate('tr_no_device_events')}</p>
            </div>
          </div>
        );
      } else {
        return (
          <div className='emptyContainer'>
            <div className='emptyContent'>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          </div>
        );
      }
    } else if (getGraphTab(deviceVersion, deviceParameters, translate).length === 1) {
      const tab = getGraphTab(deviceVersion, deviceParameters, translate)[0];
      if (isMobile) {
        return (
          <DeviceOverview
            deviceId={deviceId}
            graphTabs={getGraphTab(deviceVersion, deviceParameters, translate)}
          />
        );
      } else {
        return (
          <div className={styles.singleTabContainer}>
            <span className={styles.singleTabLabel}>{tab.graphLabel}</span>
            <div className={styles.singleTabContent}>
              <div>{renderTabContent(tab)}</div>
            </div>
          </div>
        );
      }
    } else {
      return !isMobile ? (
        <CustomTabs
          tabOption={urlParams.tab}
          onChangeTab={onChangeTab}
          tabItems={getGraphTab(deviceVersion, deviceParameters, translate).map((currentTab) => ({
            label: currentTab.label,
            key: currentTab.key,
            children: renderTabContent(currentTab),
          }))}
        />
      ) : (
        <DeviceOverview
          deviceId={deviceId}
          graphTabs={getGraphTab(deviceVersion, deviceParameters, translate)}
        />
      );
    }
  };

  return (
    <div className={styles.deviceEventChart}>
      <div className={styles.deviceInfo}>{renderDeviceData()}</div>

      {renderDeviceEvents()}
    </div>
  );
});

export default DeviceEventsView;
