export const routePaths = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  VALVES: '/valves',
  DEVICE: '/device',
  SENSORS: '/devices',
  PROFILE: '/profile',
  TIMELINE: '/timeline',
  NOTFOUND: '/not-found',
};
