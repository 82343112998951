import styled from 'styled-components';
import { List } from 'antd';

export const ListItem = styled(List.Item.Meta)`
  .ant-list-item-meta-title {
    font-size: 15px !important;
    line-height: 16px !important;
    font-weight: 600;
    color: darkslategrey !important;
  }
  .ant-list-item-meta-description {
    font-weight: 300;
  }
`;
